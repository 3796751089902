<template>
	<div>
		<input ref="input" type="file" name="image" accept="image/*" @change="setImage" />
		<div class="card card-custom content pt-2">
			<div class="card-body">
				<div class="row">
					<div class="col-md-8">
						<div class="img-cropper">
							<vue-cropper v-if="imgSrc" ref="cropper" :aspect-ratio="1" :src="imgSrc" preview=".preview" class="cropper-min-height" :key="refresh" />
							<b-button v-else v-b-tooltip="'Upload Image'" variant="success" @click="showFileChooser" class="mr-3 mb-3">
								<i class="fa fa-upload mr-2" aria-hidden="true"></i>Upload New Image
							</b-button>
						</div>
					</div>
					<div class="col-md-4">
						<div style="min-height: 209px">
							<p>Preview</p>
							<div class="cropped-img mt-2 preview secondary" />
						</div>
						<div class="d-flex justify-content-center mt-4">
							<b-button
								:class="['btn btn-md btn-success mr-3 mb-3', { 'spinner spinner-white spinner-right': submitting }]"
								v-if="imgSrc"
								@click="save()"
								>Save</b-button
							>
							<b-button class="btn btn-md btn-danger mr-13 mb-3" v-if="imgSrc" :disabled="submitting" @click="remove()">Remove</b-button>

							<span class="alert alert-info mr-10 mb-3" v-if="saved">Saved.</span>
						</div>
					</div>
					<div class="col-8 d-flex justify-content-between align-items-center">
						<div class="actions" v-if="imgSrc">
							<b-button-group vertical class="mr-3 mb-3">
								<b-button @click="zoom(0.2)">
									<i class="fa fa-search-plus" aria-hidden="true"></i>
								</b-button>
								<b-button @click="zoom(-0.2)">
									<i class="fa fa-search-minus" aria-hidden="true"></i>
								</b-button>
							</b-button-group>

							<b-button-group vertical class="mr-3 mb-3">
								<b-button @click="move(-10, 0)">
									<i class="fa fa-caret-left" aria-hidden="true"></i>
								</b-button>
								<b-button @click="move(10, 0)">
									<i class="fa fa-caret-right" aria-hidden="true"></i>
								</b-button>
							</b-button-group>

							<b-button-group vertical class="mr-3 mb-3">
								<b-button @click="move(0, -10)">
									<i class="fa fa-caret-up" aria-hidden="true"></i>
								</b-button>
								<b-button @click="move(0, 10)">
									<i class="fa fa-caret-down" aria-hidden="true"></i>
								</b-button>
							</b-button-group>

							<b-button-group vertical class="mr-3 mb-3">
								<b-button @click="rotate(90)">
									<i class="fa fa-share" aria-hidden="true"></i>
								</b-button>
								<b-button @click="rotate(-90)">
									<i class="fa fa-reply" aria-hidden="true"></i>
								</b-button>
							</b-button-group>

							<b-button @click="reset" class="mr-3 mb-3">
								<i class="fa fa-arrows-alt" aria-hidden="true"></i>
							</b-button>
						</div>
						<div>
							<b-button v-b-tooltip="'Upload Image'" v-if="imgSrc" @click="showFileChooser" class="mr-3 mb-3">
								<i class="fa fa-upload mr-2" aria-hidden="true"></i>Upload New Image
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
export default {
	props: ['data'],
	components: {
		VueCropper,
	},
	data() {
		return {
			refresh: 0,
			imgSrc: '',
			cropImg: '',
			fileExtension: 'png',
			saved: false,
			submitting: false,
		};
	},
	mounted() {
		if (this.data.avatar) {
			this.imgSrc = this.data.avatar;
			this.refresh++;
		}
	},
	methods: {
		move(offsetX, offsetY) {
			this.$refs.cropper.move(offsetX, offsetY);
		},
		reset() {
			this.$refs.cropper.reset();
		},
		rotate(deg) {
			this.$refs.cropper.rotate(deg);
		},
		setImage(e) {
			const file = e.target.files[0];
			this.fileExtension = file.name.split('.').pop();
			if (file.type.indexOf('image/') === -1) {
				alert('Please select an image file');
				return;
			}

			if (typeof FileReader === 'function') {
				const reader = new FileReader();

				reader.onload = event => {
					this.imgSrc = event.target.result;
				};

				reader.readAsDataURL(file);
			} else {
				alert('Sorry, FileReader API not supported');
			}
		},
		showFileChooser() {
			this.$refs.input.click();
		},
		save() {
			this.submitting = true;
			var img = this.$refs.cropper.getCroppedCanvas().toDataURL();
			this.$http.post('avatar', { username: this.data.username, image: img }).then(() => {
				this.$emit('save');
				this.saved = true;
				this.submitting = false;
			});
		},
		remove() {
			this.$http.post('avatar', { username: this.data.username, image: '' }).then(() => {
				var imgSrc = null;
				this.$emit('save');
			});
		},
		zoom(percent) {
			this.$refs.cropper.relativeZoom(percent);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
input[type='file'] {
	display: none;
}
.img-cropper {
	background: #ebecf5;
	min-height: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.content {
	display: flex;
	justify-content: space-between;
}

.actions {
	margin-top: 1rem;
}

.preview-area p:last-of-type {
	margin-top: 1rem;
}

.preview {
	width: 250px;
	height: 250px;
	overflow: hidden;
	border: 1px solid rgb(1, 8, 116);
}

.cropped-image img {
	max-width: 100%;
}

.cropper-bg {
	background-repeat: repeat;
}

.cropper-min-height {
	min-height: 400px;
}
</style>
